const WebsiteIcon = ({
	size = 'medium',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM4.06189 13C4.40437 15.7464 6.13899 18.0605 8.53731 19.2138C7.70259 17.6107 7.1455 15.4337 7.02469 13H4.06189ZM4.06189 11H7.02469C7.1455 8.5663 7.70258 6.38927 8.53731 4.78617C6.13899 5.93948 4.40437 8.25357 4.06189 11ZM9.02739 13C9.14295 15.094 9.61429 16.8986 10.2533 18.1766C11.0518 19.7737 11.7849 20 12 20C12.2151 20 12.9482 19.7737 13.7467 18.1766C14.3857 16.8986 14.8571 15.094 14.9726 13H9.02739ZM14.9726 11H9.02739C9.14295 8.90602 9.61429 7.10143 10.2533 5.82336C11.0518 4.22632 11.7849 4 12 4C12.2151 4 12.9482 4.22632 13.7467 5.82336C14.3857 7.10143 14.8571 8.90602 14.9726 11ZM16.9753 13C16.8545 15.4337 16.2974 17.6107 15.4627 19.2138C17.861 18.0605 19.5956 15.7464 19.9381 13H16.9753ZM19.9381 11H16.9753C16.8545 8.5663 16.2974 6.38927 15.4627 4.78617C17.861 5.93948 19.5956 8.25357 19.9381 11Z"
			fill="currentColor"
		/>
	</svg>
)

export default WebsiteIcon
